import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './login';
import HomePage from './pages/home';
import Khadyan_panji from './pages/khadyan_panji';
import Rough from './pages/rough';
import Kirana from './pages/kirana';
import Food_storage from './pages/food_storage';
import Invoice from './pages/invoice';
import Staff from './pages/staff';
import Khadyan_panji_new from './pages/khadyan_panji_new';
import Logout from './components/log_out';
import Store_profile from './pages/store_profile';
import PrivacyPolicy from './pages/privacy_policy';
import Register from './register';
import Add_kiranaItem from './pages/add_kiranaItem';
import Update_add_kiranaItem from './pages/update_add_kiranaItem';
import Vegitable_fruits from './pages/vegitable_fruits';



function App() {
  return (
    <div>
    <BrowserRouter>
      <Routes>
        {/* Define routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/khadyan_panji" element={<Khadyan_panji/>}/>
        <Route path="/rough" element={<Rough/>}/>
        <Route path='/kirana' element={<Kirana/>}/>
        <Route path='/food_storage' element={<Food_storage/>}/>
        <Route path='/invoice' element={<Invoice/>}/>
        <Route path='/staff' element={<Staff/>}/>
        <Route path='/logout' element={<Logout/>}/>
        <Route path='/khadyan_panji_new' element={<Khadyan_panji_new/>}/>
        <Route path='/store_profile' element={<Store_profile/>}/>
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>
        <Route path="/register" element={<Register/>}/>
        <Route path='/add_kiranaItem' element={<Add_kiranaItem/>}/>
        <Route path='/update_add_kiranaItem' element={<Update_add_kiranaItem/>}/>
        <Route path='/vegitable_fruits' element={<Vegitable_fruits/>}/>
     
      </Routes>
     
    </BrowserRouter>
    </div>
  );
}

export default App;
