import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Typography, Box, Button } from '@mui/material';

const Add_kiranaItem = () => {
   
    const [response, setResponse] = useState('');

    

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const formData = new FormData(event.target);
        
        try {
            const response = await fetch('https://api.rox-tech.com/ashram/add_kiranaItem.php', {
                method: 'POST',
                body: formData
            });
            
            // Check if the response is empty
            if (response.ok && response.status !== 204) {
                const data = await response.json();
                
                if (data.success) {
                    window.location.href = '/'; // Redirect to login page if registration is successful
                }
                else {
                    alert(' failed'); // Show an alert if registration fails for any other reason
                }
                
            } else {
                // Handle empty response or non-JSON response
                alert('Empty or non-JSON response received');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

   

    return (
        <div className="card card col-lg-6 col-md-8" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '3%' }}>
            <h2 style={{ textAlign: 'center', marginTop: '30px' }}>Add Kirana Item</h2>
           <div className='card-body'> 
            <form onSubmit={handleSubmit}> 
                
                <div className='row'>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="chawal_weight_p_student" className="form-label">Chawal per student in gram:</label>
                            <input className="form-control" type="number" name="chawal_weight_p_student" />
                        </div>
                        {/* other form fields */}
                        
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="chawal_rate" className="form-label">Chawal Rate for kg:</label>
                            <input className="form-control" type="number" name="chawal_rate" />
                        </div>
                        {/* other form fields */}
                       
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="aata_weight_p_student" className="form-label">Aata per student in gram:</label>
                            <input className="form-control" type="number" name="aata_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="aata_rate" className="form-label">Aata Rate for kg:</label>
                            <input className="form-control" type="number" name="aata_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="dal_weight_p_student" className="form-label">Dal per student in gram:</label>
                            <input className="form-control" type="number" name="dal_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="dal_weight_p_student" className="form-label">Dal Rate for kg:</label>
                            <input className="form-control" type="number" name="dal_rate" />
                        </div>
                    </div>
                    <div className="col-lg-12 mt-4">
                        <div className="form-group">
                            <label htmlFor="sabji_weight_p_student" className="form-label">Sabji per student in gram:</label>
                            <input className="form-control" type="number" name="sabji_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="namak_weight_p_student" className="form-label">Namak per student in gram:</label>
                            <input className="form-control" type="number" name="namak_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="namak_rate" className="form-label">namak Rate for kg:</label>
                            <input className="form-control" type="number" name="namak_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="mirchi_weight_p_student" className="form-label">Mirchi per student in gram:</label>
                            <input className="form-control" type="number" name="mirchi_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="mirchi_rate" className="form-label">Mirchi Rate for kg:</label>
                            <input className="form-control" type="number" name="mirchi_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="haldi_weight_p_student" className="form-label">Haldi per student in gram:</label>
                            <input className="form-control" type="number" name="haldi_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="haldi_rate" className="form-label">Haldi Rate for kg:</label>
                            <input className="form-control" type="number" name="haldi_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="dhaniya_weight_p_student" className="form-label">Dhaniya per student in gram:</label>
                            <input className="form-control" type="number" name="dhaniya_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="dhaniya_rate" className="form-label">Dhaniya Rate for kg:</label>
                            <input className="form-control" type="number" name="dhaniya_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="Shakkar_weight_p_student" className="form-label">Jeera per student in gram:</label>
                            <input className="form-control" type="number" name="Shakkar_weight_p_student" />
                        </div>
                    </div>
                    
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="Shakkar_rate" className="form-label">Jeera Rate for kg:</label>
                            <input className="form-control" type="number" name="Shakkar_rate" />
                        </div>
                    </div>
                    
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="tel_weight_p_student" className="form-label">Tel per student in gram:</label>
                            <input className="form-control" type="number" name="tel_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="tel_rate" className="form-label">Tel Rate for kg:</label>
                            <input className="form-control" type="number" name="tel_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="Rai_weight_p_student" className="form-label">Rai per student in gram:</label>
                            <input className="form-control" type="number" name="Rai_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="Rai_rate" className="form-label">Rai Rate for kg:</label>
                            <input className="form-control" type="number" name="Rai_rate" />
                        </div>
                    </div>
                   
                    </div>
                    <div className='row'>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="pooha_weight_p_student" className="form-label">Pooha per student in gram:</label>
                            <input className="form-control" type="number" name="pooha_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="pooha_rate" className="form-label">Pooha Rate for kg:</label>
                            <input className="form-control" type="number" name="pooha_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="muggfali_weight_p_student" className="form-label">Muggfali per student in gram:</label>
                            <input className="form-control" type="number" name="muggfali_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="muggfali_rate" className="form-label">muggfali Rate for kg:</label>
                            <input className="form-control" type="number" name="muggfali_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="chana_weight_p_student" className="form-label">Chana per student in gram:</label>
                            <input className="form-control" type="number" name="chana_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="chana_rate" className="form-label">Chana Rate for kg:</label>
                            <input className="form-control" type="number" name="chana_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="matar_weight_p_student" className="form-label">Matar per student in gram:</label>
                            <input className="form-control" type="number" name="matar_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="matar_rate" className="form-label">Matar Rate for kg:</label>
                            <input className="form-control" type="number" name="matar_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="suji_weight_p_student" className="form-label">Suji per student in gram:</label>
                            <input className="form-control" type="number" name="suji_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="suji_rate" className="form-label">Suji Rate for kg:</label>
                            <input className="form-control" type="number" name="suji_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="daliya_weight_p_student" className="form-label">Daliya per student in gram:</label>
                            <input className="form-control" type="number" name="daliya_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="daliya_rate" className="form-label">daliya Rate for kg:</label>
                            <input className="form-control" type="number" name="daliya_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="dalda_weight_p_student" className="form-label">Dalda per student in gram:</label>
                            <input className="form-control" type="number" name="dalda_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="dalda_rate" className="form-label">Dalda Rate for kg:</label>
                            <input className="form-control" type="number" name="dalda_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_shakkar_weight_p_student" className="form-label">Nasta Shakkar per student in gram:</label>
                            <input className="form-control" type="number" name="nasta_shakkar_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_shakkar_rate" className="form-label">Nasta Shakkar Rate for kg:</label>
                            <input className="form-control" type="number" name="nasta_shakkar_rate" />
                        </div>
                    </div>
                    <div className="col-lg-12 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_shakkar_suji_weight_p_student" className="form-label">Nasta shakkar suji day per student in gram:</label>
                            <input className="form-control" type="number" name="nasta_shakkar_suji_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="chaypatti_weight_p_student" className="form-label">Chaypatti per student in gram:</label>
                            <input className="form-control" type="number" name="chaypatti_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="chaypatti_rate" className="form-label">Chaypatti Rate for kg:</label>
                            <input className="form-control" type="number" name="chaypatti_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_namak_weight_p_student" className="form-label">Nasta Namak per student in gram:</label>
                            <input className="form-control" type="number" name="nasta_namak_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_namak_rate" className="form-label">Nasta namak Rate for kg:</label>
                            <input className="form-control" type="number" name="nasta_namak_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_haldi_weight_p_student" className="form-label">Nasta Haldi per student in gram:</label>
                            <input className="form-control" type="number" name="nasta_haldi_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_haldi_rate" className="form-label">Nasta Haldi Rate for kg:</label>
                            <input className="form-control" type="number" name="nasta_haldi_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_mirchi_weight_p_student" className="form-label">Nasta Mirchi per student in gram:</label>
                            <input className="form-control" type="number" name="nasta_mirchi_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_mirchi_rate" className="form-label">Nasta Mirchi Rate for kg:</label>
                            <input className="form-control" type="number" name="nasta_mirchi_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_dhaniya_weight_p_student" className="form-label">Nasta Dhaniya per student in gram:</label>
                            <input className="form-control" type="number" name="nasta_dhaniya_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_dhaniya_rate" className="form-label">Nasta Dhaniya Rate for kg:</label>
                            <input className="form-control" type="number" name="nasta_dhaniya_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_tel_weight_p_student" className="form-label">Nasta Tel per student in gram:</label>
                            <input className="form-control" type="number" name="nasta_tel_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_tel_rate" className="form-label">Nasta Tel Rate for kg:</label>
                            <input className="form-control" type="number" name="nasta_tel_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_jeera_weight_p_student" className="form-label">Nasta Jeera per student in gram:</label>
                            <input className="form-control" type="number" name="nasta_jeera_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_jeera_rate" className="form-label">Nasta Jeera Rate for kg:</label>
                            <input className="form-control" type="number" name="nasta_jeera_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_pyaj_weight_p_student" className="form-label">nasta pyaj  per student in gram:</label>
                            <input className="form-control" type="number" name="nasta_pyaj_weight_p_student" />
                        </div>
                    </div>
                    </div>
                    <div className='row'>
                     <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="bathing_soap_rate" className="form-label">Bathing soap rate:</label>
                            <input className="form-control" type="number" name="bathing_soap_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="washing_soap_rate" className="form-label">Washing soap rate:</label>
                            <input className="form-control" type="number" name="washing_soap_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nirma_rate" className="form-label">Nirma  rate:</label>
                            <input className="form-control" type="number" name="nirma_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="colgate_rate" className="form-label">Colgate  rate:</label>
                            <input className="form-control" type="number" name="colgate_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="amla_tel_rate" className="form-label">Amla tel  rate:</label>
                            <input className="form-control" type="number" name="amla_tel_rate" />
                        </div>
                    </div>
                     <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="lakdi_weight_p_student" className="form-label">lakdi Per day:</label>
                            <input className="form-control" type="number" name="lakdi_weight_p_student" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="lakdi_rate" className="form-label">lakdi rate :</label>
                            <input className="form-control" type="number" name="lakdi_rate" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_lakdi_weight" className="form-label">lakdi per day in nasta:</label>
                            <input className="form-control" type="number" name="nasta_lakdi_weight" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="nasta_lakdi_rate" className="form-label">lakdi rate in nasta:</label>
                            <input className="form-control" type="number" name="nasta_lakdi_rate" />
                        </div>
                    </div>
                    </div>
                    <input type='hidden' value={localStorage.getItem('Ashram_id')} name="Ashram_id"></input>
                <Button variant="contained" type="submit">Submit</Button>
            </form>
            </div>
            {response && <p>{response}</p>}
        </div>
    );
};

export default Add_kiranaItem;
