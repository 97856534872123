import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Typography, Box } from '@mui/material';

const Rough = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Form tabs">
          <Tab label="Lunch_Dinner" id="tab-0" />
          <Tab label="Breakfast" id="tab-1" />
          <Tab label="other" id="tab-2" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        {/* Form for Lunch_Dinner */}
        <form>
        

        </form>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {/* Form for Breakfast */}
        <form>
          {/* Dinner form elements */}
          kubkjobl
        </form>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        {/* Form for other */}
        <form>
          {/* Breakfast form elements */}
        </form>
      </TabPanel>
    </div>
  );
};

export default Rough;
