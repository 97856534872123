import React, { useState, useEffect } from "react";
import Sidebar from "../components/side_bar";
import Fixed_header from "../components/fixed_header";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";
import { GiFruitBowl } from "react-icons/gi";

const Kirana = () => {
  const [kiranaData, setKiranaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noOfStudents, setNoOfStudents] = useState(0);
  const [month, setMonth] = useState("");
  const [totalDays, setTotalDays] = useState(0);
  const [error, setError] = useState(null);
  const [updatedChawalRate, setUpdatedChawalRate] = useState({});
  const [updatedAataRates, setUpdatedAataRates] = useState([]);
  const [updateddalRates, setUpdateddalRate] = useState([]);
  const [updatedsabjiRates, setUpdatedsabjiRate] = useState([]);
  const [updatednamakRates, setUpdatednamakRate] = useState([]);
  const [updatedmirchiRates, setUpdatedmirchiRate] = useState([]);
  const [updatedhaldiRates, setUpdatedhaldiRate] = useState([]);
  const [updateddhaniyaRates, setUpdateddhaniyaRate] = useState([]);
  const [updatedlakdiRates, setUpdatedlakdiRate] = useState([]);
  const [updatedtelRates, setUpdatedtelRate] = useState([]);
  const [updatedshakkerRates, setUpdatedshakkerRate] = useState([]);
  const [updatedraiRates, setUpdatedraiRate] = useState([]);
  const [updatedpoohaRates, setUpdatedpoohaRate] = useState([]);
  const [updatedmuggfaliRates, setUpdatedmuggfaliRate] = useState([]);
  const [updatedchanaRates, setUpdatedchanaRate] = useState([]);
  const [updatedmatarRates, setUpdatedmatarRate] = useState([]);
  const [updatedsujiRates, setUpdatedsujiRate] = useState([]);
  const [updateddaliyaRates, setUpdateddaliyaRate] = useState([]);
  const [updateddaldaRates, setUpdateddaldaRate] = useState([]);
  const [updatednasta_shakkarRates, setUpdatednasta_shakkarRate] = useState([]);
  const [
    updatednasta_shakkar_sujiRates,
    setUpdatednasta_shakkar_sujirRate,
  ] = useState([]);
  const [updatedchaypattiRates, setUpdatedchaypattiRate] = useState([]);
  const [updatednasta_namakRates, setUpdatednasta_namakRate] = useState([]);
  const [updatednasta_haldiRates, setUpdatednasta_haldiRate] = useState([]);
  const [updatednasta_mirchiRates, setUpdatednasta_mirchiRate] = useState([]);
  const [updatednasta_dhaniyaRates, setUpdatednasta_dhaniyaRate] = useState([]);
  const [updatednasta_telRates, setUpdatednasta_telRate] = useState([]);
  const [updatednasta_lakdiRates, setUpdatednasta_lakdiRate] = useState([]);
  const [updatednasta_jeeraRates, setUpdatednasta_jeeraRate] = useState([]);
  const [updatedbathing_soapRates, setUpdatedbathing_soapRate] = useState([]);
  const [updatedwashing_soapRates, setUpdatedwashing_soapRate] = useState([]);
  const [updatednirmaRates, setUpdatednirmaRate] = useState([]);
  const [updatedamla_telRates, setUpdatedamla_telRate] = useState([]);
  const [updatedcolgateRates, setUpdatedcolgateRate] = useState([]);

  useEffect(() => {
    const storedUsername = localStorage.getItem("Ashram_id");
    if (!storedUsername) {
      // If username is not available, redirect the user to the login page
      window.location.href = "/login"; // Change '/login' to your desired login page URL
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.rox-tech.com/ashram/fetch_kirana_new.php?Ashram_id=${localStorage.getItem(
            "Ashram_id"
          )}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setKiranaData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    const fetchMonthData = async () => {
      try {
        const response = await fetch(
          `https://api.rox-tech.com/ashram/month.php?Ashram_id=${localStorage.getItem(
            "Ashram_id"
          )}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch month data");
        }
        const data = await response.json();
        if (data && data.length > 0) {
          setNoOfStudents(data[0].no_of_students);
          setMonth(data[0].selected_month);
          setTotalDays(data[0].total_day);
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
    fetchMonthData();
  }, []);

  const handleChawalRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedChawalRate({ ...updatedChawalRate, [index]: value });
  };
  const handleAataRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedAataRates({ ...updatedAataRates, [index]: value });
  };

  const handledalRateChange = (e, index) => {
    const value = e.target.value;
    setUpdateddalRate({ ...updateddalRates, [index]: value });
  };
  const handlesabjiRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedsabjiRate({ ...updatedsabjiRates, [index]: value });
  };
  const handlenamakRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatednamakRate({ ...updatednamakRates, [index]: value });
  };
  const handlemirchiRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedmirchiRate({ ...updatedmirchiRates, [index]: value });
  };
  const handlehaldiRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedhaldiRate({ ...updatedhaldiRates, [index]: value });
  };
  const handleddhaniyaRateChange = (e, index) => {
    const value = e.target.value;
    setUpdateddhaniyaRate({ ...updateddhaniyaRates, [index]: value });
  };
  const handledlakdiRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedlakdiRate({ ...updatedlakdiRates, [index]: value });
  };
  const handledtelRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedtelRate({ ...updatedtelRates, [index]: value });
  };
  const handledshakkerRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedshakkerRate({ ...updatedshakkerRates, [index]: value });
  };
  const handledraiRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedraiRate({ ...updatedraiRates, [index]: value });
  };
  const handledpoohaRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedpoohaRate({ ...updatedpoohaRates, [index]: value });
  };
  const handledmuggfaliRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedmuggfaliRate({ ...updatedmuggfaliRates, [index]: value });
  };
  const handledchanaRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedchanaRate({ ...updatedchanaRates, [index]: value });
  };
  const handledmatarRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedmatarRate({ ...updatedmatarRates, [index]: value });
  };
  const handledsujiRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedsujiRate({ ...updatedsujiRates, [index]: value });
  };
  const handleddaliyaRateChange = (e, index) => {
    const value = e.target.value;
    setUpdateddaliyaRate({ ...updateddaliyaRates, [index]: value });
  };
  const handleddaldaRateChange = (e, index) => {
    const value = e.target.value;
    setUpdateddaldaRate({ ...updateddaldaRates, [index]: value });
  };
  const handlednasta_shakkarRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatednasta_shakkarRate({
      ...updatednasta_shakkarRates,
      [index]: value,
    });
  };
  const handlednasta_shakkar_sujiRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatednasta_shakkar_sujirRate({
      ...updatednasta_shakkar_sujiRates,
      [index]: value,
    });
  };
  const handledchaypattiRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedchaypattiRate({ ...updatedchaypattiRates, [index]: value });
  };
  const handlednasta_namakRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatednasta_namakRate({ ...updatednasta_namakRates, [index]: value });
  };
  const handlednasta_haldiRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatednasta_haldiRate({ ...updatednasta_haldiRates, [index]: value });
  };
  const handlednasta_mirchiRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatednasta_mirchiRate({ ...updatednasta_mirchiRates, [index]: value });
  };
  const handlednasta_dhaniyaRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatednasta_dhaniyaRate({
      ...updatednasta_dhaniyaRates,
      [index]: value,
    });
  };
  const handlednasta_telRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatednasta_telRate({ ...updatednasta_telRates, [index]: value });
  };
  const handlednasta_lakdiRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatednasta_lakdiRate({ ...updatednasta_lakdiRates, [index]: value });
  };
  const handlednasta_jeeraRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatednasta_jeeraRate({ ...updatednasta_jeeraRates, [index]: value });
  };
  const handledbathing_soapRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedbathing_soapRate({ ...updatedbathing_soapRates, [index]: value });
  };
  const handledwashing_soapRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedwashing_soapRate({ ...updatedwashing_soapRates, [index]: value });
  };
  const handlednirmaRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatednirmaRate({ ...updatednirmaRates, [index]: value });
  };
  const handledamla_telRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedamla_telRate({ ...updatedamla_telRates, [index]: value });
  };
  const handledcolgateRateChange = (e, index) => {
    const value = e.target.value;
    setUpdatedcolgateRate({ ...updatedcolgateRates, [index]: value });
  };

  const calculateTotal = () => {
    let total = 0;
  
    kiranaData.forEach((item, index) => {
      let poohaTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 0 || i === 5 || i === 10 || i === 15 || i === 20 || i === 25 || i === 30) {
          poohaTotal += item.pooha_weight_p_student * noOfStudents;
        }
      };
      let muggfaliTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 0 ||
          i === 5 ||
          i === 10 ||
          i === 15 ||
          i === 20 ||
          i === 25 ||
          i === 30) {
          muggfaliTotal += item.muggfali_weight_p_student * noOfStudents;
        }
      }
      let chanaTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 1 ||
          i === 6 ||
          i === 11 ||
          i === 16 ||
          i === 21 ||
          i === 26 ||
          i === 31) {
            chanaTotal += item.chana_weight_p_student * noOfStudents;
        }
      }
      let matarTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 2 ||
          i === 7 ||
          i === 12 ||
          i === 17 ||
          i === 22 ||
          i === 27 ||
          i === 32) {
            matarTotal += item.matar_weight_p_student * noOfStudents;
        }
      }
      let sujiTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 2 ||
          i === 7 ||
          i === 12 ||
          i === 17 ||
          i === 22 ||
          i === 27 ||
          i === 32) {
            sujiTotal += item.suji_weight_p_student * noOfStudents;
        }
      }
      let daliyaTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 2 ||
          i === 7 ||
          i === 12 ||
          i === 17 ||
          i === 22 ||
          i === 27 ||
          i === 32) {
            daliyaTotal += item.daliya_weight_p_student * noOfStudents;
        }
      }
      let daldaTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 2 ||
          i === 7 ||
          i === 12 ||
          i === 17 ||
          i === 22 ||
          i === 27 ||
          i === 32) {
            daldaTotal += item.dalda_weight_p_student * noOfStudents;
        }
      }
      let nasta_shakkarTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 0 ||
          i === 1 ||
          i === 2 ||
          i === 4 ||
          i === 5 ||
          i === 6 ||
          i === 7 ||
          i === 9 ||
          i === 10 ||
          i === 11 ||
          i === 12 ||
          i === 14 ||
          i === 15 ||
          i === 16 ||
          i === 17 ||
          i === 19 ||
          i === 20 ||
          i === 21 ||
          i === 22 ||
          i === 24 ||
          i === 25 ||
          i === 26 ||
          i === 20 ||
          i === 21 ||
          i === 29 ||
          i === 27 ||
          i === 30 ||
          i === 31) {
            nasta_shakkarTotal += item.nasta_shakkar_weight_p_student * noOfStudents;
        }
      }
      let nasta_shakkar_sujiTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 3 ||
          i === 8 ||
          i === 13 ||
          i === 18 ||
          i === 23 ||
          i === 28 ||
          i === 33) {
            nasta_shakkar_sujiTotal += item.nasta_shakkar_suji_weight_p_student * noOfStudents;
        }
      }
      let chaypattiTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 0 ||
          i === 2 ||
          i === 3 ||
          i === 4 ||
          i === 5 ||
          i === 6 ||
          i === 7 ||
          i === 8 ||
          i === 9 ||
          i === 10 ||
          i === 11 ||
          i === 12 ||
          i === 13 ||
          i === 14 ||
          i === 15 ||
          i === 16 ||
          i === 17 ||
          i === 18 ||
          i === 19 ||
          i === 20 ||
          i === 21 ||
          i === 22 ||
          i === 23 ||
          i === 24 ||
          i === 25 ||
          i === 26 ||
          i === 27 ||
          i === 28 ||
          i === 29 ||
          i === 30 ||
          i === 31) {
            chaypattiTotal += item.chaypatti_weight_p_student * noOfStudents;
        }
      }
      let nasta_namakTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 0 ||
          i === 1 ||
          i === 2 ||
          i === 4 ||
          i === 5 ||
          i === 6 ||
          i === 7 ||
          i === 9 ||
          i === 9 ||
          i === 10 ||
          i === 11 ||
          i === 12 ||
          i === 14 ||
          i === 15 ||
          i === 16 ||
          i === 17 ||
          i === 19 ||
          i === 20 ||
          i === 21 ||
          i === 22 ||
          i === 24 ||
          i === 25 ||
          i === 26 ||
          i === 27 ||
          i === 29 ||
          i === 30 ||
          i === 31) {
            nasta_namakTotal += item.nasta_namak_weight_p_student * noOfStudents;
        }
      }
      let nasta_haldiTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 0 ||
          i === 1 ||
          i === 2 ||
          i === 4 ||
          i === 5 ||
          i === 6 ||
          i === 7 ||
          i === 9 ||
          i === 9 ||
          i === 10 ||
          i === 11 ||
          i === 12 ||
          i === 14 ||
          i === 15 ||
          i === 16 ||
          i === 17 ||
          i === 19 ||
          i === 20 ||
          i === 21 ||
          i === 22 ||
          i === 24 ||
          i === 25 ||
          i === 26 ||
          i === 27 ||
          i === 29 ||
          i === 30 ||
          i === 31) {
            nasta_haldiTotal += item.nasta_haldi_weight_p_student * noOfStudents;
        }
      }
      let nasta_mirchiTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 0 ||
          i === 1 ||
          i === 2 ||
          i === 4 ||
          i === 5 ||
          i === 6 ||
          i === 7 ||
          i === 9 ||
          i === 9 ||
          i === 10 ||
          i === 11 ||
          i === 12 ||
          i === 14 ||
          i === 15 ||
          i === 16 ||
          i === 17 ||
          i === 19 ||
          i === 20 ||
          i === 21 ||
          i === 22 ||
          i === 24 ||
          i === 25 ||
          i === 26 ||
          i === 27 ||
          i === 29 ||
          i === 30) {
            nasta_mirchiTotal += item.nasta_mirchi_weight_p_student * noOfStudents;
        }
      }
      let nasta_dhaniyaTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 0 ||
          i === 1 ||
          i === 2 ||
          i === 4 ||
          i === 5 ||
          i === 6 ||
          i === 7 ||
          i === 9 ||
          i === 9 ||
          i === 10 ||
          i === 11 ||
          i === 12 ||
          i === 14 ||
          i === 15 ||
          i === 16 ||
          i === 17 ||
          i === 19 ||
          i === 20 ||
          i === 21 ||
          i === 22 ||
          i === 24 ||
          i === 25 ||
          i === 26 ||
          i === 27 ||
          i === 29 ||
          i === 30) {
            nasta_dhaniyaTotal += item.nasta_dhaniya_weight_p_student * noOfStudents;
        }
      }
      let nasta_telTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 0 ||
          i === 1 ||
          i === 2 ||
          i === 4 ||
          i === 5 ||
          i === 6 ||
          i === 7 ||
          i === 9 ||
          i === 9 ||
          i === 10 ||
          i === 11 ||
          i === 12 ||
          i === 14 ||
          i === 15 ||
          i === 16 ||
          i === 17 ||
          i === 19 ||
          i === 20 ||
          i === 21 ||
          i === 22 ||
          i === 24 ||
          i === 25 ||
          i === 26 ||
          i === 27 ||
          i === 29 ||
          i === 30 ||
          i === 31) {
            nasta_telTotal += item.nasta_tel_weight_p_student * noOfStudents;
        }
      }
      let nasta_jeeraTotal = 0;
      for (let i = 0; i < totalDays; i++) {
        if (i === 0 ||
          i === 1 ||
          i === 2 ||
          i === 4 ||
          i === 5 ||
          i === 6 ||
          i === 7 ||
          i === 9 ||
          i === 9 ||
          i === 10 ||
          i === 11 ||
          i === 12 ||
          i === 14 ||
          i === 15 ||
          i === 16 ||
          i === 17 ||
          i === 19 ||
          i === 20 ||
          i === 21 ||
          i === 22 ||
          i === 24 ||
          i === 25 ||
          i === 26 ||
          i === 27 ||
          i === 29 ||
          i === 30 ||
          i === 31) {
            nasta_jeeraTotal += item.nasta_jeera_weight_p_student * noOfStudents;
        }
      }
      total +=
        ((updatedChawalRate[index] || item.chawal_rate) * 2 *
          item.chawal_weight_p_student * noOfStudents * totalDays) / 1000 +
        ((updatedAataRates[index] || item.aata_rate) *
          item.aata_weight_p_student * 2 *
          noOfStudents *
          totalDays) /
          1000 +
        ((updateddalRates[index] || item.dal_rate) * 2 *
          item.dal_weight_p_student *
          noOfStudents *
          totalDays) /
          1000 +
        ((updatednamakRates[index] || item.namak_rate) *
          item.namak_weight_p_student *
          noOfStudents * 2 *
          totalDays) /
          1000 +
        ((updatedmirchiRates[index] || item.mirchi_rate) *
          item.mirchi_weight_p_student * 2 *
          noOfStudents *
          totalDays) /
          1000 +
        ((updatedhaldiRates[index] || item.haldi_rate) *
          item.haldi_weight_p_student *
          noOfStudents * 2 *
          totalDays) /
          1000 +
        ((updateddhaniyaRates[index] || item.dhaniya_rate) *
          item.dhaniya_weight_p_student *
          noOfStudents * 2 *
          totalDays) /
          1000 +
        (updatedlakdiRates[index] || item.lakdi_rate) * totalDays +
        ((updatedtelRates[index] || item.tel_rate) *
          item.tel_weight_p_student * 2 *
          noOfStudents *
          totalDays) /
          1000 +
        ((updatedshakkerRates[index] || item.Shakkar_rate) *
          item.Shakkar_weight_p_student *
          noOfStudents * 2 *
          totalDays) /
          1000 +
        ((updatedraiRates[index] || item.Rai_rate) *
          item.Rai_weight_p_student * 2 *
          noOfStudents *
          totalDays) /
          1000 +
        ((updatedwashing_soapRates[index] || item.washing_soap_rate) *
          item.washing_soap_weight_p_student * totalDays) +
        ((updatedbathing_soapRates[index] || item.bathing_soap_rate) *
          item.bathing_soap_weight_p_student * totalDays) +
        ((updatednirmaRates[index] || item.nirma_rate) *
          item.nirma_weight_p_student * totalDays) +
        ((updatedamla_telRates[index] || item.amla_tel_rate) *
          item.amla_tel_weight_p_student * totalDays) +
        ((updatedcolgateRates[index] || item.colgate_rate) *
          item.colgate_weight_p_student * totalDays) +
        ((updatedpoohaRates[index] || item.pooha_rate) * poohaTotal) / 1000 +
        ((updatedmuggfaliRates[index] || item.muggfali_rate) * muggfaliTotal) / 1000 +
        ((updatedchanaRates[index] || item.chana_rate) * chanaTotal) / 1000 +
        ((updatedmatarRates[index] || item.matar_rate) * matarTotal) / 1000 +
        ((updatedsujiRates[index] || item.suji_rate) * sujiTotal) / 1000 +
        ((updateddaliyaRates[index] || item.daliya_rate) * daliyaTotal) / 1000 +
        ((updateddaldaRates[index] || item.dalda_rate) * daldaTotal) / 1000  +
        ((updatednasta_shakkarRates[index] || item.nasta_shakkar_rate) * nasta_shakkarTotal) / 1000 +
        ((updatednasta_shakkar_sujiRates[index] || item.nasta_shakkar_rate) * nasta_shakkar_sujiTotal) / 1000 +
        ((updatedchaypattiRates[index] || item.chaypatti_rate) * chaypattiTotal) / 1000 +
        ((updatednasta_namakRates[index] || item.nasta_namak_rate) * nasta_namakTotal) / 1000 +
        ((updatednasta_haldiRates[index] || item.nasta_haldi_rate) * nasta_haldiTotal) / 1000  +
        ((updatednasta_mirchiRates[index] || item.nasta_mirchi_rate) * nasta_mirchiTotal) / 1000 +
        ((updatednasta_dhaniyaRates[index] || item.nasta_dhaniya_rate) * nasta_dhaniyaTotal) / 1000 +
        ((updatednasta_telRates[index] || item.nasta_tel_rate) * nasta_telTotal) / 1000 +
        ((updatednasta_jeeraRates[index] || item.nasta_jeera_rate) * nasta_jeeraTotal) / 1000 +
        ((updatednasta_lakdiRates[index] || item.nasta_lakdi_rate) * totalDays);
    });
  
    return total;
  };
  
  const total = calculateTotal();


  const [items, setItems] = useState([
    { id: 1, name: 'aalu', weight: 0, rate: 20 },
    { id: 2, name: 'tomatar', weight: 0, rate: 20 },
    { id: 3, name: 'Mithi', weight: 0, rate: 20 },
    { id: 4, name: 'bhinf', weight: 0, rate: 20 },
    { id: 5, name: 'aalu', weight: 0, rate: 20 }
  ]);
  const [itemsWeekTwo, setItemsWeekTwo] = useState([
    // Define your data for week two here
    { id: 1, name: 'banana', weight: 5, rate: 30 },
    { id: 2, name: 'apple', weight: 0, rate: 40 },
    { id: 3, name: 'orange', weight: 0, rate: 25 },
    { id: 4, name: 'grapes', weight: 0, rate: 35 },
    { id: 5, name: 'watermelon', weight: 0, rate: 15 }
  ]);
 
  const [totalWeightWeekOne, setTotalWeightWeekOne] = useState(0);
  const [totalPrizeWeekOne, setTotalPrizeWeekOne] = useState(0);
  const [totalWeightWeekTwo, setTotalWeightWeekTwo] = useState(0);
  const [totalPrizeWeekTwo, setTotalPrizeWeekTwo] = useState(0);
  const [totalWeight, setTotalWeight] = useState();
  const [totalPrize, setTotalPrize] = useState(0);
 


useEffect(() => {
  calculateTotalWeekOne();
  calculateTotalWeekTwo();
  calculateTotalsabji();
}, [items, itemsWeekTwo]);




  const handleWeightChange = (id, value, week) => {
  const setter = week === 'week1' ? setItems : setItemsWeekTwo;
  setter(prevItems =>
    prevItems.map(item =>
      item.id === id ? { ...item, weight: parseFloat(value) } : item
    )
  );
};


  const handleRateChange = (id, value, week) => {
    const setter = week === 'week1' ? setItems : setItemsWeekTwo;
    setter(prevItems =>
      prevItems.map(item =>
        item.id === id ? { ...item, rate: parseFloat(value) } : item
      )
    );
  };

  const calculatePrize = (weight, rate) => {
    // Check if weight and rate are valid numbers
    if (!isNaN(weight) && !isNaN(rate)) {
      return weight * rate;
    } else {
      // Return 0 if either weight or rate is not a valid number
      return 0;
    }
  };

  const calculateTotalWeekOne = () => {
    const totalWeight = items.reduce((total, item) => total + item.weight, 0);
    const totalPrize = items.reduce((total, item) => total + calculatePrize(item.weight, item.rate), 0);
    setTotalWeightWeekOne(totalWeight);
    setTotalPrizeWeekOne(totalPrize);
  };

  const calculateTotalWeekTwo = () => {
    const totalWeight = itemsWeekTwo.reduce((total, item) => total + item.weight, 0);
    const totalPrize = itemsWeekTwo.reduce((total, item) => total + calculatePrize(item.weight, item.rate), 0);
    setTotalWeightWeekTwo(totalWeight);
    setTotalPrizeWeekTwo(totalPrize);
  };
  
  
  
  // Function to handle rate change for the third week
  const calculateTotalsabji = () => {
    // Combine items from both weeks into a single array
    const allItems = [...items, ...itemsWeekTwo];

    // Calculate total weight and prize for all items
    const totalWeight = allItems.reduce((total, item) => total + item.weight, 0);
    const totalPrize = allItems.reduce((total, item) => total + calculatePrize(item.weight, item.rate), 0);

    // Update total weight and prize states
    setTotalWeight(totalWeight);
    setTotalPrize(totalPrize);
};

  
  
  

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          <Fixed_header />

          <div className="nk-content">
            <div className="container">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head">
                    <div className="nk-block-head-between flex-wrap gap g-2">
                      <div className="nk-block-head-content">
                        <h2 className="nk-block-title">Kirana</h2>
                        <nav>
                          <ol className="breadcrumb breadcrumb-arrow mb-0">
                            <li className="breadcrumb-item">
                              <a href="#">Home</a>
                            </li>
                            <li className="breadcrumb-item">
                              <a href="#">Kirana</a>
                            </li>
                          </ol>
                        </nav>
                      </div>
                      <div className="nk-block-head-content"></div>
                    </div>
                  </div>

                  <div className="nk-block">
                    <div className="card">
                      <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div className="dataTable-top">
                          <h2>No of students: {noOfStudents}</h2>
                          <h2>Month: {month}</h2>
                          <h2>Total Days: {totalDays}</h2>
                          <h2 style={{position: 'fixed',background: 'white',boxShadow: '0px 0px 5px 0px',padding: '9px',borderRadius: '5px',
          left: '82%', top: '15%',zIndex: '999'
        }}>Total: {total + (totalPrize * 2)}</h2>
                        </div>
                        <div className="dataTable-container table-responsive">
                          <table
                            className="datatable-init table dataTable-table"
                            data-nk-container="table-responsive"
                          >
                            <thead className="table-light">
                              <tr>
                                <th>Item name</th>
                                <th className="d-none d-md-block">Gram/Student</th>
                                <th>Weight this month</th>
                                <th>Rate</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {/* Loop over kiranaData */}
                                {kiranaData.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <td>Chawal</td>
                                    <td className="d-none d-md-block">{item.chawal_weight_p_student}</td>
                                    <td>
                                      {(item.chawal_weight_p_student *
                                        2 *
                                        noOfStudents *
                                        totalDays) /
                                        1000}
                                      kg
                                    </td>
                                    <td>
                                      <input
                                        min="0"
                                        name={`chawal_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatedChawalRate[index] !== undefined
                                            ? updatedChawalRate[index]
                                            : item.chawal_rate
                                        }
                                        onChange={(e) =>
                                          handleChawalRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatedChawalRate[index] ||
                                        item.chawal_rate) *
                                        2 *
                                        item.chawal_weight_p_student *
                                        noOfStudents *
                                        totalDays) /
                                        1000}
                                      ₹
                                    </td>
                                  </React.Fragment>
                                ))}
                              </tr>
                              {/* ======================================ata==================================== */}
                              {kiranaData.map((item, index) => (
                                <tr key={index}>
                                  <td>Aata</td>
                                  <td className="d-none d-md-block">{item.aata_weight_p_student}</td>
                                  <td>
                                    {(item.aata_weight_p_student *
                                      2 *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                    kg
                                  </td>
                                  <td>
                                    <input
                                      name={`aata_rate_${index}`}
                                      className="form-control"
                                      type="number"
                                      value={
                                        updatedAataRates[index] !== undefined
                                          ? updatedAataRates[index]
                                          : item.aata_rate
                                      }
                                      onChange={(e) =>
                                        handleAataRateChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    {((updatedAataRates[index] ||
                                      item.aata_rate) *
                                      2 *
                                      item.aata_weight_p_student *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                  </td>
                                </tr>
                              ))}
                              {/* ==================================================================== */}

                              {kiranaData.map((item, index) => (
                                <tr key={index}>
                                  <td>Dal</td>
                                  <td className="d-none d-md-block">{item.dal_weight_p_student}</td>
                                  <td>
                                    {(item.dal_weight_p_student *
                                      2 *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                    kg
                                  </td>
                                  <td>
                                    <input
                                      name={`dal_rate_${index}`}
                                      className="form-control"
                                      type="number"
                                      value={
                                        updateddalRates[index] !== undefined
                                          ? updateddalRates[index]
                                          : item.dal_rate
                                      }
                                      onChange={(e) =>
                                        handledalRateChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    {((updateddalRates[index] ||
                                      item.dal_rate) *
                                      item.dal_weight_p_student * 2 *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                  </td>
                                </tr>
                              ))}
                              
                              {kiranaData.map((item, index) => (
                                <tr key={index}>
                                  <td>Namak</td>
                                  <td className="d-none d-md-block">{item.namak_weight_p_student}</td>
                                  <td>
                                    {(item.namak_weight_p_student *
                                      2 *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                    kg
                                  </td>
                                  <td>
                                    <input
                                      name={`namak_rate_${index}`}
                                      className="form-control"
                                      type="number"
                                      value={
                                        updatednamakRates[index] !== undefined
                                          ? updatednamakRates[index]
                                          : item.namak_rate
                                      }
                                      onChange={(e) =>
                                        handlenamakRateChange(e, index)
                                      }
                                    />
                                  </td>

                                  <td>
                                    {((updatednamakRates[index] ||
                                      item.namak_rate) *
                                      item.namak_weight_p_student * 2 *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                  </td>
                                </tr>
                              ))}
                              {kiranaData.map((item, index) => (
                                <tr key={index}>
                                  <td>Mirchi</td>
                                  <td className="d-none d-md-block">{item.mirchi_weight_p_student}</td>
                                  <td>
                                    {(item.mirchi_weight_p_student *
                                      2 *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                    kg
                                  </td>
                                  <td>
                                    <input
                                      name={`mirchi_rate_${index}`}
                                      className="form-control"
                                      type="number"
                                      value={
                                        updatedmirchiRates[index] !== undefined
                                          ? updatedmirchiRates[index]
                                          : item.mirchi_rate
                                      }
                                      onChange={(e) =>
                                        handlemirchiRateChange(e, index)
                                      }
                                    />
                                  </td>

                                  <td>
                                    {((updatedmirchiRates[index] ||
                                      item.mirchi_rate) *
                                      item.mirchi_weight_p_student *
                                      noOfStudents * 2 *
                                      totalDays) /
                                      1000}
                                  </td>
                                </tr>
                              ))}
                              {kiranaData.map((item, index) => (
                                <tr key={index}>
                                  <td>Haldi</td>
                                  <td className="d-none d-md-block">{item.haldi_weight_p_student}</td>
                                  <td>
                                    {(item.haldi_weight_p_student *
                                      2 *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                    kg
                                  </td>
                                  <td>
                                    <input
                                      name={`haldi_rate_${index}`}
                                      className="form-control"
                                      type="number"
                                      value={
                                        updatedhaldiRates[index] !== undefined
                                          ? updatedhaldiRates[index]
                                          : item.haldi_rate
                                      }
                                      onChange={(e) =>
                                        handlehaldiRateChange(e, index)
                                      }
                                    />
                                  </td>

                                  <td>
                                    {((updatedhaldiRates[index] ||
                                      item.haldi_rate) *
                                      item.haldi_weight_p_student *
                                      noOfStudents * 2 *
                                      totalDays) /
                                      1000}
                                  </td>
                                </tr>
                              ))}
                              {kiranaData.map((item, index) => (
                                <tr key={index}>
                                  <td>Dhaniya</td>
                                  <td className="d-none d-md-block">{item.dhaniya_weight_p_student}</td>
                                  <td>
                                    {(item.dhaniya_weight_p_student *
                                      2 *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                    kg
                                  </td>
                                  <td>
                                    <input
                                      name={`dhaniya_rate_${index}`}
                                      className="form-control"
                                      type="number"
                                      value={
                                        updateddhaniyaRates[index] !== undefined
                                          ? updateddhaniyaRates[index]
                                          : item.dhaniya_rate
                                      }
                                      onChange={(e) =>
                                        handleddhaniyaRateChange(e, index)
                                      }
                                    />
                                  </td>

                                  <td>
                                    {((updateddhaniyaRates[index] ||
                                      item.dhaniya_rate) *
                                      item.dhaniya_weight_p_student *
                                      noOfStudents * 2 *
                                      totalDays) /
                                      1000}
                                  </td>
                                </tr>
                              ))}
                              
                              {kiranaData.map((item, index) => (
                                <tr key={index}>
                                  <td>Tel</td>
                                  <td className="d-none d-md-block">{item.tel_weight_p_student}</td>
                                  <td>
                                    {(item.tel_weight_p_student *
                                      2 *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                    kg
                                  </td>
                                  <td>
                                    <input
                                      name={`tel_rate_${index}`}
                                      className="form-control"
                                      type="number"
                                      value={
                                        updatedtelRates[index] !== undefined
                                          ? updatedtelRates[index]
                                          : item.tel_rate
                                      }
                                      onChange={(e) =>
                                        handledtelRateChange(e, index)
                                      }
                                    />
                                  </td>

                                  <td>
                                    {((updatedtelRates[index] ||
                                      item.tel_rate) *
                                      item.tel_weight_p_student *
                                      noOfStudents * 2 *
                                      totalDays) /
                                      1000}
                                  </td>
                                </tr>
                              ))}
                              {kiranaData.map((item, index) => (
                                <tr key={index}>
                                  <td>Jeera</td>
                                  <td className="d-none d-md-block">{item.Shakkar_weight_p_student}</td>
                                  <td>
                                    {(item.Shakkar_weight_p_student *
                                      2 *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                    kg
                                  </td>
                                  <td>
                                    <input
                                      name={`shakker_rate_${index}`}
                                      className="form-control"
                                      type="number"
                                      value={
                                        updatedshakkerRates[index] !== undefined
                                          ? updatedshakkerRates[index]
                                          : item.Shakkar_rate
                                      }
                                      onChange={(e) =>
                                        handledshakkerRateChange(e, index)
                                      }
                                    />
                                  </td>

                                  <td>
                                    {((updatedshakkerRates[index] ||
                                      item.Shakkar_rate) *
                                      item.Shakkar_weight_p_student * 2 *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                  </td>
                                </tr>
                              ))}
                              {kiranaData.map((item, index) => (
                                <tr key={index}>
                                  <td>Rai</td>
                                  <td className="d-none d-md-block">{item.Rai_weight_p_student}</td>
                                  <td>
                                    {(item.Rai_weight_p_student *
                                      2 *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                    kg
                                  </td>
                                  <td>
                                    <input
                                      name={`rai_rate_${index}`}
                                      className="form-control"
                                      type="number"
                                      value={
                                        updatedraiRates[index] !== undefined
                                          ? updatedraiRates[index]
                                          : item.Rai_rate
                                      }
                                      onChange={(e) =>
                                        handledraiRateChange(e, index)
                                      }
                                    />
                                  </td>

                                  <td>
                                    {((updatedraiRates[index] ||
                                      item.Rai_rate) *
                                      item.Rai_weight_p_student * 2 *
                                      noOfStudents *
                                      totalDays) /
                                      1000}
                                  </td>
                                </tr>
                              ))}

                              <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let washing_soapTotal = item.washing_soap_weight_p_student * totalDays ;
                               
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>washing_soap</td>
                                    <td className="d-none d-md-block"></td>
                                    <td>{item.washing_soap_weight_p_student * totalDays}</td>
                                    <td>
                                      <input
                                        name={`washing_soap_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatedwashing_soapRates[index] !==
                                          undefined
                                            ? updatedwashing_soapRates[index]
                                            : item.washing_soap_rate
                                        }
                                        onChange={(e) =>
                                          handledwashing_soapRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatedwashing_soapRates[index] ||
                                        item.washing_soap_rate) *
                                        washing_soapTotal)}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                               
                                let bathing_soapTotal = item.bathing_soap_weight_p_student * totalDays ;
                               
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>bathing_soap</td>
                                    <td className="d-none d-md-block"></td>
                                    <td>{item.bathing_soap_weight_p_student * totalDays}</td>
                                    <td>
                                      <input
                                        name={`bathing_soap_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatedbathing_soapRates[index] !==
                                          undefined
                                            ? updatedbathing_soapRates[index]
                                            : item.bathing_soap_rate
                                        }
                                        onChange={(e) =>
                                          handledbathing_soapRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatedbathing_soapRates[index] ||
                                        item.bathing_soap_rate) *
                                        bathing_soapTotal)}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let nirmaTotal = item.nirma_weight_p_student * totalDays ;
                               
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>nirma</td>
                                    <td className="d-none d-md-block"></td>
                                    <td>{item.nirma_weight_p_student * totalDays}</td>
                                    <td>
                                      <input
                                        name={`nirma_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatednirmaRates[index] !==
                                          undefined
                                            ? updatednirmaRates[index]
                                            : item.nirma_rate
                                        }
                                        onChange={(e) =>
                                          handlednirmaRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatednirmaRates[index] ||
                                        item.nirma_rate) *
                                        nirmaTotal)}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let amla_telTotal = item.amla_tel_weight_p_student * totalDays ;
                               
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>amla_tel</td>
                                    <td className="d-none d-md-block"></td>
                                    <td>{item.amla_tel_weight_p_student * totalDays}</td>
                                    <td>
                                      <input
                                        name={`amla_tel_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatedamla_telRates[index] !==
                                          undefined
                                            ? updatedamla_telRates[index]
                                            : item.amla_tel_rate
                                        }
                                        onChange={(e) =>
                                          handledamla_telRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatedamla_telRates[index] ||
                                        item.amla_tel_rate) *
                                        amla_telTotal)}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let colgateTotal = item.colgate_weight_p_student * totalDays ;
                               
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>colgate</td>
                                    <td className="d-none d-md-block"></td>
                                    <td>{item.colgate_weight_p_student * totalDays}</td>
                                    <td>
                                      <input
                                        name={`colgate_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatedcolgateRates[index] !==
                                          undefined
                                            ? updatedcolgateRates[index]
                                            : item.colgate_rate
                                        }
                                        onChange={(e) =>
                                          handledcolgateRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatedcolgateRates[index] ||
                                        item.colgate_rate) *
                                        colgateTotal)}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-4">
                      <h5 className="mt-3" style={{ marginLeft: "20px" }}>
                        Breakfast
                      </h5>
                      <div className="dataTable-container table-responsive">
                        <table
                          className="datatable-init table dataTable-table"
                          data-nk-container="table-responsive"
                        >
                          <thead className="table-light">
                            <tr>
                              <th>Item name</th>
                              <th className="d-none d-md-block">Gram/Student</th>
                              <th>Weight this month</th>
                              <th>Rate</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                let poohaTotal = 0; // Define poohaTotal inside the loop
                                // Calculate poohaTotal
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 0 ||
                                    i === 5 ||
                                    i === 10 ||
                                    i === 15 ||
                                    i === 20 ||
                                    i === 25 ||
                                    i === 30
                                  ) {
                                    poohaTotal +=
                                      item.pooha_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>Pooha</td>
                                    <td className="d-none d-md-block">{item.pooha_weight_p_student}</td>
                                    <td>{poohaTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`pooha_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatedpoohaRates[index] !== undefined
                                            ? updatedpoohaRates[index]
                                            : item.pooha_rate
                                        }
                                        onChange={(e) =>
                                          handledpoohaRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatedpoohaRates[index] ||
                                        item.pooha_rate) *
                                        poohaTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                              
                                let muggfaliTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 0 ||
                                    i === 5 ||
                                    i === 10 ||
                                    i === 15 ||
                                    i === 20 ||
                                    i === 25 ||
                                    i === 30
                                  ) {
                                    muggfaliTotal +=
                                      item.muggfali_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>muggfali</td>
                                    <td className="d-none d-md-block">{item.muggfali_weight_p_student}</td>
                                    <td>{muggfaliTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`muggfali_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatedmuggfaliRates[index] !==
                                          undefined
                                            ? updatedmuggfaliRates[index]
                                            : item.muggfali_rate
                                        }
                                        onChange={(e) =>
                                          handledmuggfaliRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatedmuggfaliRates[index] ||
                                        item.muggfali_rate) *
                                        muggfaliTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let chanaTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 1 ||
                                    i === 6 ||
                                    i === 11 ||
                                    i === 16 ||
                                    i === 21 ||
                                    i === 26 ||
                                    i === 31
                                  ) {
                                    chanaTotal +=
                                      item.chana_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>Chana</td>
                                    <td className="d-none d-md-block">{item.chana_weight_p_student}</td>
                                    <td>{chanaTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`chana_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatedchanaRates[index] !== undefined
                                            ? updatedchanaRates[index]
                                            : item.chana_rate
                                        }
                                        onChange={(e) =>
                                          handledchanaRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatedchanaRates[index] ||
                                        item.chana_rate) *
                                        chanaTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let matarTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 2 ||
                                    i === 7 ||
                                    i === 12 ||
                                    i === 17 ||
                                    i === 22 ||
                                    i === 27 ||
                                    i === 32
                                  ) {
                                    matarTotal +=
                                      item.matar_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>matar</td>
                                    <td className="d-none d-md-block">{item.matar_weight_p_student}</td>
                                    <td>{matarTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`matar_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatedmatarRates[index] !== undefined
                                            ? updatedmatarRates[index]
                                            : item.matar_rate
                                        }
                                        onChange={(e) =>
                                          handledmatarRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatedmatarRates[index] ||
                                        item.matar_rate) *
                                        matarTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let sujiTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 3 ||
                                    i === 8 ||
                                    i === 13 ||
                                    i === 18 ||
                                    i === 23 ||
                                    i === 28 ||
                                    i === 33
                                  ) {
                                    sujiTotal +=
                                      item.suji_weight_p_student * noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>suji</td>
                                    <td className="d-none d-md-block">{item.suji_weight_p_student}</td>
                                    <td>{sujiTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`suji_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatedsujiRates[index] !== undefined
                                            ? updatedsujiRates[index]
                                            : item.suji_rate
                                        }
                                        onChange={(e) =>
                                          handledsujiRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatedsujiRates[index] ||
                                        item.suji_rate) *
                                        sujiTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let daliyaTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 4 ||
                                    i === 9 ||
                                    i === 14 ||
                                    i === 19 ||
                                    i === 24 ||
                                    i === 29
                                  ) {
                                    daliyaTotal +=
                                      item.daliya_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>daliya</td>
                                    <td className="d-none d-md-block">{item.daliya_weight_p_student}</td>
                                    <td>{daliyaTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`daliya_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updateddaliyaRates[index] !==
                                          undefined
                                            ? updateddaliyaRates[index]
                                            : item.daliya_rate
                                        }
                                        onChange={(e) =>
                                          handleddaliyaRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updateddaliyaRates[index] ||
                                        item.daliya_rate) *
                                        daliyaTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let daldaTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 3 ||
                                    i === 8 ||
                                    i === 13 ||
                                    i === 18 ||
                                    i === 23 ||
                                    i === 28
                                  ) {
                                    daldaTotal +=
                                      item.dalda_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>dalda</td>
                                    <td className="d-none d-md-block">{item.dalda_weight_p_student}</td>
                                    <td>{daldaTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`dalda_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updateddaldaRates[index] !== undefined
                                            ? updateddaldaRates[index]
                                            : item.dalda_rate
                                        }
                                        onChange={(e) =>
                                          handleddaldaRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updateddaldaRates[index] ||
                                        item.dalda_rate) *
                                        daldaTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let nasta_shakkarTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 0 ||
                                    i === 1 ||
                                    i === 2 ||
                                    i === 4 ||
                                    i === 5 ||
                                    i === 6 ||
                                    i === 7 ||
                                    i === 9 ||
                                    i === 10 ||
                                    i === 11 ||
                                    i === 12 ||
                                    i === 14 ||
                                    i === 15 ||
                                    i === 16 ||
                                    i === 17 ||
                                    i === 19 ||
                                    i === 20 ||
                                    i === 21 ||
                                    i === 22 ||
                                    i === 24 ||
                                    i === 25 ||
                                    i === 26 ||
                                    i === 20 ||
                                    i === 21 ||
                                    i === 29 ||
                                    i === 27 ||
                                    i === 30 ||
                                    i === 31
                                  ) {
                                    nasta_shakkarTotal +=
                                      item.nasta_shakkar_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>nasta_shakkar</td>
                                    <td className="d-none d-md-block">
                                      {item.nasta_shakkar_weight_p_student}
                                    </td>
                                    <td>{nasta_shakkarTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`nasta_shakkar_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatednasta_shakkarRates[index] !==
                                          undefined
                                            ? updatednasta_shakkarRates[index]
                                            : item.nasta_shakkar_rate
                                        }
                                        onChange={(e) =>
                                          handlednasta_shakkarRateChange(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatednasta_shakkarRates[index] ||
                                        item.nasta_shakkar_rate) *
                                        nasta_shakkarTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let nasta_shakkar_sujiTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 3 ||
                                    i === 8 ||
                                    i === 13 ||
                                    i === 18 ||
                                    i === 23 ||
                                    i === 28 ||
                                    i === 33
                                  ) {
                                    nasta_shakkar_sujiTotal +=
                                      item.nasta_shakkar_suji_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>nasta_shakkar_suji</td>
                                    <td className="d-none d-md-block">
                                      {item.nasta_shakkar_suji_weight_p_student}
                                    </td>
                                    <td>{nasta_shakkar_sujiTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`nasta_shakkar_suji_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatednasta_shakkar_sujiRates[
                                            index
                                          ] !== undefined
                                            ? updatednasta_shakkar_sujiRates[
                                                index
                                              ]
                                            : item.nasta_shakkar_rate
                                        }
                                        onChange={(e) =>
                                          handlednasta_shakkar_sujiRateChange(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatednasta_shakkar_sujiRates[
                                        index
                                      ] || item.nasta_shakkar_suji_rate) *
                                        nasta_shakkar_sujiTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let chaypattiTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 0 ||
                                    i === 2 ||
                                    i === 3 ||
                                    i === 4 ||
                                    i === 5 ||
                                    i === 6 ||
                                    i === 7 ||
                                    i === 8 ||
                                    i === 9 ||
                                    i === 10 ||
                                    i === 11 ||
                                    i === 12 ||
                                    i === 13 ||
                                    i === 14 ||
                                    i === 15 ||
                                    i === 16 ||
                                    i === 17 ||
                                    i === 18 ||
                                    i === 19 ||
                                    i === 20 ||
                                    i === 21 ||
                                    i === 22 ||
                                    i === 23 ||
                                    i === 24 ||
                                    i === 25 ||
                                    i === 26 ||
                                    i === 27 ||
                                    i === 28 ||
                                    i === 29 ||
                                    i === 30 ||
                                    i === 31
                                  ) {
                                    chaypattiTotal +=
                                      item.chaypatti_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>chaypatti</td>
                                    <td className="d-none d-md-block">{item.chaypatti_weight_p_student}</td>
                                    <td>{chaypattiTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`chaypatti_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatedchaypattiRates[index] !==
                                          undefined
                                            ? updatedchaypattiRates[index]
                                            : item.chaypatti_rate
                                        }
                                        onChange={(e) =>
                                          handledchaypattiRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatedchaypattiRates[index] ||
                                        item.chaypatti_rate) *
                                        chaypattiTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let nasta_namakTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 0 ||
                                    i === 1 ||
                                    i === 2 ||
                                    i === 4 ||
                                    i === 5 ||
                                    i === 6 ||
                                    i === 7 ||
                                    i === 9 ||
                                    i === 9 ||
                                    i === 10 ||
                                    i === 11 ||
                                    i === 12 ||
                                    i === 14 ||
                                    i === 15 ||
                                    i === 16 ||
                                    i === 17 ||
                                    i === 19 ||
                                    i === 20 ||
                                    i === 21 ||
                                    i === 22 ||
                                    i === 24 ||
                                    i === 25 ||
                                    i === 26 ||
                                    i === 27 ||
                                    i === 29 ||
                                    i === 30
                                  ) {
                                    nasta_namakTotal +=
                                      item.nasta_namak_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>nasta_namak</td>
                                    <td className="d-none d-md-block">{item.nasta_namak_weight_p_student}</td>
                                    <td>{nasta_namakTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`nasta_namak_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatednasta_namakRates[index] !==
                                          undefined
                                            ? updatednasta_namakRates[index]
                                            : item.nasta_namak_rate
                                        }
                                        onChange={(e) =>
                                          handlednasta_namakRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatednasta_namakRates[index] ||
                                        item.nasta_namak_rate) *
                                        nasta_namakTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}

                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let nasta_haldiTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 0 ||
                                    i === 1 ||
                                    i === 2 ||
                                    i === 4 ||
                                    i === 5 ||
                                    i === 6 ||
                                    i === 7 ||
                                    i === 9 ||
                                    i === 9 ||
                                    i === 10 ||
                                    i === 11 ||
                                    i === 12 ||
                                    i === 14 ||
                                    i === 15 ||
                                    i === 16 ||
                                    i === 17 ||
                                    i === 19 ||
                                    i === 20 ||
                                    i === 21 ||
                                    i === 22 ||
                                    i === 24 ||
                                    i === 25 ||
                                    i === 26 ||
                                    i === 27 ||
                                    i === 29 ||
                                    i === 30
                                  ) {
                                    nasta_haldiTotal +=
                                      item.nasta_haldi_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>nasta_haldi</td>
                                    <td className="d-none d-md-block">{item.nasta_haldi_weight_p_student}</td>
                                    <td>{nasta_haldiTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`nasta_haldi_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatednasta_haldiRates[index] !==
                                          undefined
                                            ? updatednasta_haldiRates[index]
                                            : item.nasta_haldi_rate
                                        }
                                        onChange={(e) =>
                                          handlednasta_haldiRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatednasta_haldiRates[index] ||
                                        item.nasta_haldi_rate) *
                                        nasta_haldiTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let nasta_mirchiTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 0 ||
                                    i === 1 ||
                                    i === 2 ||
                                    i === 4 ||
                                    i === 5 ||
                                    i === 6 ||
                                    i === 7 ||
                                    i === 9 ||
                                    i === 9 ||
                                    i === 10 ||
                                    i === 11 ||
                                    i === 12 ||
                                    i === 14 ||
                                    i === 15 ||
                                    i === 16 ||
                                    i === 17 ||
                                    i === 19 ||
                                    i === 20 ||
                                    i === 21 ||
                                    i === 22 ||
                                    i === 24 ||
                                    i === 25 ||
                                    i === 26 ||
                                    i === 27 ||
                                    i === 29 ||
                                    i === 30
                                  ) {
                                    nasta_mirchiTotal +=
                                      item.nasta_mirchi_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>nasta_mirchi</td>
                                    <td className="d-none d-md-block">
                                      {item.nasta_mirchi_weight_p_student}
                                    </td>
                                    <td>{nasta_mirchiTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`nasta_mirchi_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatednasta_mirchiRates[index] !==
                                          undefined
                                            ? updatednasta_mirchiRates[index]
                                            : item.nasta_mirchi_rate
                                        }
                                        onChange={(e) =>
                                          handlednasta_mirchiRateChange(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatednasta_mirchiRates[index] ||
                                        item.nasta_mirchi_rate) *
                                        nasta_mirchiTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let nasta_dhaniyaTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 0 ||
                                    i === 1 ||
                                    i === 2 ||
                                    i === 4 ||
                                    i === 5 ||
                                    i === 6 ||
                                    i === 7 ||
                                    i === 9 ||
                                    i === 9 ||
                                    i === 10 ||
                                    i === 11 ||
                                    i === 12 ||
                                    i === 14 ||
                                    i === 15 ||
                                    i === 16 ||
                                    i === 17 ||
                                    i === 19 ||
                                    i === 20 ||
                                    i === 21 ||
                                    i === 22 ||
                                    i === 24 ||
                                    i === 25 ||
                                    i === 26 ||
                                    i === 27 ||
                                    i === 29 ||
                                    i === 30
                                  ) {
                                    nasta_dhaniyaTotal +=
                                      item.nasta_dhaniya_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>nasta_dhaniya</td>
                                    <td className="d-none d-md-block">
                                      {item.nasta_dhaniya_weight_p_student}
                                    </td>
                                    <td>{nasta_dhaniyaTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`nasta_dhaniya_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatednasta_dhaniyaRates[index] !==
                                          undefined
                                            ? updatednasta_dhaniyaRates[index]
                                            : item.nasta_dhaniya_rate
                                        }
                                        onChange={(e) =>
                                          handlednasta_dhaniyaRateChange(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatednasta_dhaniyaRates[index] ||
                                        item.nasta_dhaniya_rate) *
                                        nasta_dhaniyaTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let nasta_telTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 0 ||
                                    i === 1 ||
                                    i === 2 ||
                                    i === 4 ||
                                    i === 5 ||
                                    i === 6 ||
                                    i === 7 ||
                                    i === 9 ||
                                    i === 9 ||
                                    i === 10 ||
                                    i === 11 ||
                                    i === 12 ||
                                    i === 14 ||
                                    i === 15 ||
                                    i === 16 ||
                                    i === 17 ||
                                    i === 19 ||
                                    i === 20 ||
                                    i === 21 ||
                                    i === 22 ||
                                    i === 24 ||
                                    i === 25 ||
                                    i === 26 ||
                                    i === 27 ||
                                    i === 29 ||
                                    i === 30 ||
                                    i === 31
                                  ) {
                                    nasta_telTotal +=
                                      item.nasta_tel_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>nasta_tel</td>
                                    <td className="d-none d-md-block">{item.nasta_tel_weight_p_student}</td>
                                    <td>{nasta_telTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`nasta_tel_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatednasta_telRates[index] !==
                                          undefined
                                            ? updatednasta_telRates[index]
                                            : item.nasta_tel_rate
                                        }
                                        onChange={(e) =>
                                          handlednasta_telRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatednasta_telRates[index] ||
                                        item.nasta_tel_rate) *
                                        nasta_telTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                           

                            {/* ======================================ata==================================== */}
                            <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let nasta_jeeraTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 0 ||
                                    i === 1 ||
                                    i === 2 ||
                                    i === 4 ||
                                    i === 5 ||
                                    i === 6 ||
                                    i === 7 ||
                                    i === 9 ||
                                    i === 9 ||
                                    i === 10 ||
                                    i === 11 ||
                                    i === 12 ||
                                    i === 14 ||
                                    i === 15 ||
                                    i === 16 ||
                                    i === 17 ||
                                    i === 19 ||
                                    i === 20 ||
                                    i === 21 ||
                                    i === 22 ||
                                    i === 24 ||
                                    i === 25 ||
                                    i === 26 ||
                                    i === 27 ||
                                    i === 29 ||
                                    i === 30 ||
                                    i === 31
                                  ) {
                                    nasta_jeeraTotal +=
                                      item.nasta_jeera_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>nasta_jeera</td>
                                    <td className="d-none d-md-block">{item.nasta_jeera_weight_p_student}</td>
                                    <td>{nasta_jeeraTotal / 1000}</td>
                                    <td>
                                      <input
                                        name={`nasta_jeera_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatednasta_jeeraRates[index] !==
                                          undefined
                                            ? updatednasta_jeeraRates[index]
                                            : item.nasta_jeera_rate
                                        }
                                        onChange={(e) =>
                                          handlednasta_jeeraRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {((updatednasta_jeeraRates[index] ||
                                        item.nasta_tel_rate) *
                                        nasta_jeeraTotal) /
                                        1000}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>

                            {/* ======================================ata==================================== */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="card" style={{padding:'10px',marginTop:'20px'}}>
                  <h5 className="title">Other Kirana Item</h5>
                  <div className="table-responsive">
              <table className="table table-middle mb-0">
                <thead className="table-light table-head-sm"></thead>
                <tbody className="table-light">
              
                            {kiranaData.map((item, index) => (
                                <tr key={index}>
                                  <td> nasta lakdi</td>
                                  <td></td>
                                  <td>{totalDays}item</td>
                                  <td>
                                    <input
                                      name={`lakdi_rate_${index}`}
                                      className="form-control"
                                      type="number"
                                      value={
                                        updatedlakdiRates[index] !== undefined
                                          ? updatedlakdiRates[index]
                                          : item.lakdi_rate
                                      }
                                      onChange={(e) =>
                                        handledlakdiRateChange(e, index)
                                      }
                                    />
                                  </td>

                                  <td>
                                    {(updatedlakdiRates[index] ||
                                      item.lakdi_rate) * totalDays}
                                  </td>
                                </tr>
                              ))}
                            
                             <tr>
                              {/* Loop over kiranaData */}
                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let nasta_lakdiTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (
                                    i === 0 ||
                                    i === 1 ||
                                    i === 2 ||
                                    i === 3 ||
                                    i === 4 ||
                                    i === 5 ||
                                    i === 6 ||
                                    i === 7 ||
                                    i === 8 ||
                                    i === 9 ||
                                    i === 10 ||
                                    i === 11 ||
                                    i === 12 ||
                                    i === 13 ||
                                    i === 14 ||
                                    i === 15 ||
                                    i === 16 ||
                                    i === 17 ||
                                    i === 18 ||
                                    i === 19 ||
                                    i === 20 ||
                                    i === 21 ||
                                    i === 22 ||
                                    i === 23 ||
                                    i === 24 ||
                                    i === 25 ||
                                    i === 26 ||
                                    i === 27 ||
                                    i === 28 ||
                                    i === 29 ||
                                    i === 30 ||
                                    i === 31 ||
                                    i === 32 ||
                                    i === 33
                                  ) {
                                    nasta_lakdiTotal +=
                                      item.nasta_lakdi_weight * 2;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                    <td>lakdi</td>
                                    <td className="d-none d-md-block"></td>
                                    <td>{nasta_lakdiTotal}</td>
                                    <td>
                                      <input
                                        name={`nasta_lakdi_rate_${index}`}
                                        className="form-control"
                                        type="number"
                                        value={
                                          updatednasta_lakdiRates[index] !==
                                          undefined
                                            ? updatednasta_lakdiRates[index]
                                            : item.nasta_lakdi_rate
                                        }
                                        onChange={(e) =>
                                          handlednasta_lakdiRateChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {(updatednasta_lakdiRates[index] ||
                                        item.nasta_lakdi_rate) *
                                        nasta_lakdiTotal}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>
                           
                           
                            
                            </tbody>
                            </table>
                            </div>
                  </div>
                  <div className="card" style={{padding:'10px',marginTop:'20px'}}>
        <h5 className="title">
        {kiranaData.map((item, index) => (
                    
                    <React.Fragment key={index}>
                      
                     Sabji Have to &nbsp;

                              {kiranaData.map((item, index) => {
                                // Define poohaTotal inside the loop
                                let nasta_pyaj_weight_p_studentTotal = 0;
                                for (let i = 0; i < totalDays; i++) {
                                  if (i === 0 || i === 1    || i === 3  || i === 4 || i === 5  || i === 7 || i === 8 || i === 9 
             || i === 11 || i === 11 || i === 12 ||   i === 13 || i === 15 || i === 16 || i === 17 ||
              i === 19 || i === 20 || i === 21  || i === 23 || i === 24 || i === 25 || i === 27 ||
               i === 28 || i === 29
                                  ) {
                                    nasta_pyaj_weight_p_studentTotal +=
                                      item.nasta_pyaj_weight_p_student *
                                      noOfStudents;
                                  }
                                }
                                // Return JSX for each item in kiranaData
                                return (
                                  <React.Fragment key={index}>
                                     {(item.sabji_weight_p_student * 2 *
                          noOfStudents *
                          totalDays /1000)+(nasta_pyaj_weight_p_studentTotal /1000)}
                                  </React.Fragment>
                                );
                              })}
                      
                         
                        kg
                      
                      

                      
                    </React.Fragment>
                  ))}
                  &nbsp;
        Now  Total Weight: {totalWeight * 2}kg
          &nbsp;
          Total Prize: {totalPrize * 2}
        </h5>
      </div>
                  <div className="row">
        {/* ======================================================first week---------------------------------                   */}
        <div className="col-xxl-6">
          <div className="card mt-4">
            <div className="card-body flex-grow-0 py-2">
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">First week  </h5>
                </div>
                
                
                <div className="card-title">
                  <h5 className="title">weight-{totalWeightWeekOne } </h5>
                  <h5>prize-:{totalPrizeWeekOne}</h5>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-middle mb-0">
                <thead className="table-light table-head-sm">
                  <tr>
                    <th className="tb-col">
                      <span className="overline-title">
                        Veg name
                      </span>
                    </th>
                    <th className="tb-col tb-col-end">
                      <span className="overline-title">
                        Weight
                      </span>
                    </th>
                    <th className="tb-col tb-col-end">
                      <span className="overline-title">
                        Rate
                      </span>
                    </th>
                    <th className="tb-col tb-col-end">
                      <span className="overline-title">
                        Prize
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={item.id}>
                      <td className="tb-col">
                        <div className="media-group">
                          <div className="media media-md flex-shrink-0 media-middle media-circle text-bg-dark">
                            <GiFruitBowl />
                          </div>
                          <div className="media-text">
                            <span className="title">{item.name}</span>
                          </div>
                        </div>
                      </td>
                      <td className="tb-col tb-col-end">
                        <span className="change up small">
                          <input
                            min="0"
                            name={`weight_week1_${index}`}
                            className="form-control"
                            type="number"
                            value={item.weight}
                            onChange={(e) => handleWeightChange(item.id, e.target.value, 'week1')}
                          />
                        </span>
                      </td>
                      <td className="tb-col tb-col-end">
                        <span className="small">
                          <input
                            min="0"
                            name={`rate_week1_${index}`}
                            className="form-control"
                            type="number"
                            value={item.rate}
                            onChange={(e) => handleRateChange(item.id, e.target.value, 'week1')}
                          />
                        </span>
                      </td>
                      <td className="tb-col tb-col-end">
                        <span className="text-info small">
                          {calculatePrize(item.weight, item.rate)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* ======================================================end first week--------------------------------- */}
        <div className="col-xxl-6">
          <div className="card mt-4">
            <div className="card-body flex-grow-0 py-2">
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">Second  week  </h5>
                </div>
                
                <div className="card-title">
                  <h5 className="title">weight-{totalWeightWeekTwo } </h5>
                  <h5>prize-:{totalPrizeWeekTwo}</h5>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-middle mb-0">
                <thead className="table-light table-head-sm">
                  <tr>
                    <th className="tb-col">
                      <span className="overline-title">
                        Veg name
                      </span>
                    </th>
                    <th className="tb-col tb-col-end">
                      <span className="overline-title">
                        Weight
                      </span>
                    </th>
                    <th className="tb-col tb-col-end">
                      <span className="overline-title">
                        Rate
                      </span>
                    </th>
                    <th className="tb-col tb-col-end">
                      <span className="overline-title">
                        Prize
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {itemsWeekTwo.map((item, index) => (
                    <tr key={item.id}>
                      <td className="tb-col">
                        <div className="media-group">
                          <div className="media media-md flex-shrink-0 media-middle media-circle text-bg-dark">
                            <GiFruitBowl />
                          </div>
                          <div className="media-text">
                            <span className="title">{item.name}</span>
                          </div>
                        </div>
                      </td>
                      <td className="tb-col tb-col-end">
                        <span className="change up small">
                          <input
                            min="0"
                            name={`weight_week2_${index}`}
                            className="form-control"
                            type="number"
                            value={item.weight}
                            onChange={(e) => handleWeightChange(item.id, e.target.value, 'week2')}
                          />
                        </span>
                      </td>
                      <td className="tb-col tb-col-end">
                        <span className="small">
                          <input
                            min="0"
                            name={`rate_week2_${index}`}
                            className="form-control"
                            type="number"
                            value={item.rate}
                            onChange={(e) => handleRateChange(item.id, e.target.value, 'week2')}
                          />
                        </span>
                      </td>
                      <td className="tb-col tb-col-end">
                        <span className="text-info small">
                          {calculatePrize(item.weight, item.rate)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* =======third week */}
        <div className="col-xxl-6">
          <div className="card mt-4">
            <div className="card-body flex-grow-0 py-2">
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">Third week  </h5>
                </div>
                
                
                <div className="card-title">
                  <h5 className="title">weight-{totalWeightWeekOne } </h5>
                  <h5>prize-:{totalPrizeWeekOne}</h5>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-middle mb-0">
                <thead className="table-light table-head-sm">
                  <tr>
                    <th className="tb-col">
                      <span className="overline-title">
                        Veg name
                      </span>
                    </th>
                    <th className="tb-col tb-col-end">
                      <span className="overline-title">
                        Weight
                      </span>
                    </th>
                    <th className="tb-col tb-col-end">
                      <span className="overline-title">
                        Rate
                      </span>
                    </th>
                    <th className="tb-col tb-col-end">
                      <span className="overline-title">
                        Prize
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={item.id}>
                      <td className="tb-col">
                        <div className="media-group">
                          <div className="media media-md flex-shrink-0 media-middle media-circle text-bg-dark">
                            <GiFruitBowl />
                          </div>
                          <div className="media-text">
                            <span className="title">{item.name}</span>
                          </div>
                        </div>
                      </td>
                      <td className="tb-col tb-col-end">
                        <span className="change up small">
                          <input
                            min="0"
                            name={`weight_week1_${index}`}
                            className="form-control"
                            type="number"
                            value={item.weight}
                            onChange={(e) => handleWeightChange(item.id, e.target.value, 'week1')}
                          />
                        </span>
                      </td>
                      <td className="tb-col tb-col-end">
                        <span className="small">
                          <input
                            min="0"
                            name={`rate_week1_${index}`}
                            className="form-control"
                            type="number"
                            value={item.rate}
                            onChange={(e) => handleRateChange(item.id, e.target.value, 'week1')}
                          />
                        </span>
                      </td>
                      <td className="tb-col tb-col-end">
                        <span className="text-info small">
                          {calculatePrize(item.weight, item.rate)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* ======================================================end first week--------------------------------- */}
        <div className="col-xxl-6">
          <div className="card mt-4">
            <div className="card-body flex-grow-0 py-2">
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">Forth week  </h5>
                </div>
                
                <div className="card-title">
                  <h5 className="title">weight-{totalWeightWeekTwo } </h5>
                  <h5>prize-:{totalPrizeWeekTwo}</h5>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-middle mb-0">
                <thead className="table-light table-head-sm">
                  <tr>
                    <th className="tb-col">
                      <span className="overline-title">
                        Veg name
                      </span>
                    </th>
                    <th className="tb-col tb-col-end">
                      <span className="overline-title">
                        Weight
                      </span>
                    </th>
                    <th className="tb-col tb-col-end">
                      <span className="overline-title">
                        Rate
                      </span>
                    </th>
                    <th className="tb-col tb-col-end">
                      <span className="overline-title">
                        Prize
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {itemsWeekTwo.map((item, index) => (
                    <tr key={item.id}>
                      <td className="tb-col">
                        <div className="media-group">
                          <div className="media media-md flex-shrink-0 media-middle media-circle text-bg-dark">
                            <GiFruitBowl />
                          </div>
                          <div className="media-text">
                            <span className="title">{item.name}</span>
                          </div>
                        </div>
                      </td>
                      <td className="tb-col tb-col-end">
                        <span className="change up small">
                          <input
                            min="0"
                            name={`weight_week2_${index}`}
                            className="form-control"
                            type="number"
                            value={item.weight}
                            onChange={(e) => handleWeightChange(item.id, e.target.value, 'week2')}
                          />
                        </span>
                      </td>
                      <td className="tb-col tb-col-end">
                        <span className="small">
                          <input
                            min="0"
                            name={`rate_week2_${index}`}
                            className="form-control"
                            type="number"
                            value={item.rate}
                            onChange={(e) => handleRateChange(item.id, e.target.value, 'week2')}
                          />
                        </span>
                      </td>
                      <td className="tb-col tb-col-end">
                        <span className="text-info small">
                          {calculatePrize(item.weight, item.rate)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* =======third week */}

        {/* ===========third week */}
      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kirana;
