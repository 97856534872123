import React from 'react'
import ConnectionCheck from '../components/ConnectionCheck';
import Sidebar from '../components/side_bar';
import Fixed_header from '../components/fixed_header';
import { Link } from "react-router-dom";

const Vegitable_fruits = () => {
  return (
    <div class="nk-app-root">
    <ConnectionCheck/>
    <div className='nk-main'>
    <Sidebar/>
    <div className='nk-wrap'>
{/* ==================================================================== */}
<Fixed_header/>
    <div className="card card col-lg-12" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '3%' }}>
        <h2 style={{ textAlign: 'center', marginTop: '30px' }}>Vegitables and Fruits</h2>
        <div className='card-body'>
            <div className='row vegi-outer'>
            

            <div className='col-lg-3 col-6  mb-4'>
                <div className='vegi-content'>
                <div className='vegi-content-inn'>
                    <span>52% off</span>
                    <img src='https://www.bigbasket.com/media/uploads/p/m/10000025_27-fresho-banana-robusta.jpg?tr=w-1920,q=80'></img>
                    </div>
                    <div className='vegi-prizes'>
                       <h5>Banana</h5> 
                       <h6>20₹/kg</h6>
                       <Link to="">Add to card</Link>
                    </div>
                   
                    
                </div>
            </div>

            <div className='col-lg-3 col-6 mb-4'>
                <div className='vegi-content'>
                <div className='vegi-content-inn'>
                    <span>52% off</span>
                    <img src='https://www.bigbasket.com/media/uploads/p/m/10000067_23-fresho-capsicum-green.jpg?tr=w-1920,q=80'></img>
                    </div>
                    <div className='vegi-prizes'>
                       <h5>Banana</h5> 
                       <h6>20₹/kg</h6>
                       <Link to="">Add to card</Link>
                    </div>
                   
                    
                </div>
            </div>

            <div className='col-lg-3 col-6 mb-4'>
                <div className='vegi-content'>
                <div className='vegi-content-inn'>
                    <span>52% off</span>
                    <img src='https://www.bigbasket.com/media/uploads/p/m/10000074_19-fresho-cauliflower.jpg?tr=w-1920,q=80'></img>
                    </div>
                    <div className='vegi-prizes'>
                       <h5>Banana</h5> 
                       <h6>20₹/kg</h6>
                       <Link to="">Add to card</Link>
                    </div>
                   
                    
                </div>
            </div>

            <div className='col-lg-3 col-6 mb-4'>
                <div className='vegi-content'>
                <div className='vegi-content-inn'>
                    <span>52% off</span>
                    <img src='https://www.bigbasket.com/media/uploads/p/m/10000338_13-fresho-ginger.jpg?tr=w-1920,q=80'></img>
                    </div>
                    <div className='vegi-prizes'>
                       <h5>Banana</h5> 
                       <h6>20₹/kg</h6>
                       <Link to="">Add to card</Link>
                    </div>
                   
                    
                </div>
            </div>

            <div className='col-lg-3 col-6 mb-4'>
                <div className='vegi-content'>
                <div className='vegi-content-inn'>
                    <span>52% off</span>
                    <img src='https://www.bigbasket.com/media/uploads/p/m/10000335_15-fresho-lemon.jpg?tr=w-1920,q=80'></img>
                    </div>
                    <div className='vegi-prizes'>
                       <h5>Banana</h5> 
                       <h6>20₹/kg</h6>
                       <Link to="">Add to card</Link>
                    </div>
                   
                    
                </div>
            </div>

            <div className='col-lg-3 col-6 mb-4'>
                <div className='vegi-content'>
                <div className='vegi-content-inn'>
                    <span>52% off</span>
                    <img src='https://www.bigbasket.com/media/uploads/p/m/1228332_1-fresho-tomato-local.jpg?tr=w-1920,q=80'></img>
                    </div>
                    <div className='vegi-prizes'>
                       <h5>Banana</h5> 
                       <h6>20₹/kg</h6>
                       <Link to="">Add to card</Link>
                    </div>
                   
                    
                </div>
            </div>

            <div className='col-lg-3 col-6 mb-4'>
                <div className='vegi-content'>
                <div className='vegi-content-inn'>
                    <span>52% off</span>
                    <img src='https://www.bigbasket.com/media/uploads/p/m/10000025_27-fresho-banana-robusta.jpg?tr=w-1920,q=80'></img>
                    </div>
                    <div className='vegi-prizes'>
                       <h5>Banana</h5> 
                       <h6>20₹/kg</h6>
                       <Link to="">Add to card</Link>
                    </div>
                   
                    
                </div>
            </div>

            <div className='col-lg-3 col-6 mb-4'>
                <div className='vegi-content'>
                <div className='vegi-content-inn'>
                    <span>52% off</span>
                    <img src='https://www.bigbasket.com/media/uploads/p/m/10000025_27-fresho-banana-robusta.jpg?tr=w-1920,q=80'></img>
                    </div>
                    <div className='vegi-prizes'>
                       <h5>Banana</h5> 
                       <h6>20₹/kg</h6>
                       <Link to="">Add to card</Link>
                    </div>
                   
                    
                </div>
            </div>

            


            

            </div>
        </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Vegitable_fruits;
