import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import ConnectionCheck from '../components/ConnectionCheck';
import Sidebar from '../components/side_bar';
import Fixed_header from '../components/fixed_header';

const Update_add_kiranaItem = () => {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch data from the API when the component mounts
        fetchData();
    }, []);

    const fetchData = () => {
        // Retrieve Ashram_id from localStorage
        const Ashram_id = localStorage.getItem('Ashram_id');

        // Make the API request
        fetch(`https://api.rox-tech.com/ashram/get_kiranaItem2updte.php?Ashram_id=${Ashram_id}`)
            .then(response => response.json())
            .then(data => {
                setFormData(data); // Set the fetched data to the state
                setLoading(false); // Update loading state to false
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false); // Update loading state to false even in case of an error
            });
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);

        try {
            const response = await fetch('https://api.rox-tech.com/ashram/update_kirana.php', {
                method: 'POST',
                body: formData
            });

            // Check if the response is ok
            if (response.ok) {
                const data = await response.json();

                if (data.success) {
                    alert('Update successful'); // Show alert on success
                    // Optionally, you can redirect to another page here
                } else {
                    alert('Update failed'); // Show alert on failure
                }
            } else {
                // Handle non-ok response
                console.error('Update failed:', response.statusText);
            }
        } catch (error) {
            // Handle error
            console.error('Error:', error);
        }
    };

    return (
        <div class="nk-app-root">
        <ConnectionCheck/>
        <div className='nk-main'>
        <Sidebar/>
        <div className='nk-wrap'>
    {/* ==================================================================== */}
    <Fixed_header/>
        <div className="card card col-lg-12 col-md-8" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '3%' }}>
            <h2 style={{ textAlign: 'center', marginTop: '30px' }}>Update Kirana Item</h2>
            <div className='card-body'>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'chawal_weight_p_student'} className="form-label">{'chawal_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'chawal_weight_p_student'} value={formData['chawal_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, chawal_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'chawal_rate'} className="form-label">{'chawal_rate'}:</label>
                                <input className="form-control" type="number" name={'chawal_rate'} value={formData['chawal_rate'] || ''} onChange={(e) => setFormData({ ...formData, chawal_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'aata_weight_p_student'} className="form-label">{'aata_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'aata_weight_p_student'} value={formData['aata_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, aata_weight_p_student: e.target.value })} />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'aata_rate'} className="form-label">{'aata_rate'}:</label>
                                <input className="form-control" type="number" name={'aata_rate'} value={formData['aata_rate'] || ''} onChange={(e) => setFormData({ ...formData, aata_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'dal_weight_p_student'} className="form-label">{'dal_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'dal_weight_p_student'} value={formData['dal_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, dal_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'dal_rate'} className="form-label">{'dal_rate'}:</label>
                                <input className="form-control" type="number" name={'dal_rate'} value={formData['dal_rate'] || ''} onChange={(e) => setFormData({ ...formData, dal_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label htmlFor={'sabji_weight_p_student'} className="form-label">{'sabji_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'sabji_weight_p_student'} value={formData['sabji_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, sabji_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'namak_weight_p_student'} className="form-label">{'namak_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'namak_weight_p_student'} value={formData['namak_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, namak_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'namak_rate'} className="form-label">{'namak_rate'}:</label>
                                <input className="form-control" type="number" name={'namak_rate'} value={formData['namak_rate'] || ''} onChange={(e) => setFormData({ ...formData, namak_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'mirchi_weight_p_student'} className="form-label">{'mirchi_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'mirchi_weight_p_student'} value={formData['mirchi_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, mirchi_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'mirchi_rate'} className="form-label">{'mirchi_rate'}:</label>
                                <input className="form-control" type="number" name={'mirchi_rate'} value={formData['mirchi_rate'] || ''} onChange={(e) => setFormData({ ...formData, mirchi_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'haldi_weight_p_student'} className="form-label">{'haldi_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'haldi_weight_p_student'} value={formData['haldi_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, haldi_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'haldi_rate'} className="form-label">{'haldi_rate'}:</label>
                                <input className="form-control" type="number" name={'haldi_rate'} value={formData['haldi_rate'] || ''} onChange={(e) => setFormData({ ...formData, haldi_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'dhaniya_weight_p_student'} className="form-label">{'dhaniya_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'dhaniya_weight_p_student'} value={formData['dhaniya_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, dhaniya_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'dhaniya_rate'} className="form-label">{'dhaniya_rate'}:</label>
                                <input className="form-control" type="number" name={'dhaniya_rate'} value={formData['dhaniya_rate'] || ''} onChange={(e) => setFormData({ ...formData, dhaniya_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'Shakkar_weight_p_student'} className="form-label">Jeera:</label>
                                <input className="form-control" type="number" name={'Shakkar_weight_p_student'} value={formData['Shakkar_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, Shakkar_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'Shakkar_rate'} className="form-label">Jeera rate:</label>
                                <input className="form-control" type="number" name={'Shakkar_rate'} value={formData['Shakkar_rate'] || ''} onChange={(e) => setFormData({ ...formData, Shakkar_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'tel_weight_p_student'} className="form-label">{'tel_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'tel_weight_p_student'} value={formData['tel_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, tel_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'tel_rate'} className="form-label">{'tel_rate'}:</label>
                                <input className="form-control" type="number" name={'tel_rate'} value={formData['tel_rate'] || ''} onChange={(e) => setFormData({ ...formData, tel_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'Rai_weight_p_student'} className="form-label">{'Rai_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'Rai_weight_p_student'} value={formData['Rai_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, Rai_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'Rai_rate'} className="form-label">{'Rai_rate'}:</label>
                                <input className="form-control" type="number" name={'Rai_rate'} value={formData['Rai_rate'] || ''} onChange={(e) => setFormData({ ...formData, Rai_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'pooha_weight_p_student'} className="form-label">{'pooha_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'pooha_weight_p_student'} value={formData['pooha_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, pooha_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'pooha_rate'} className="form-label">{'pooha_rate'}:</label>
                                <input className="form-control" type="number" name={'pooha_rate'} value={formData['pooha_rate'] || ''} onChange={(e) => setFormData({ ...formData, pooha_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'muggfali_weight_p_student'} className="form-label">{'muggfali_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'muggfali_weight_p_student'} value={formData['muggfali_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, muggfali_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'muggfali_rate'} className="form-label">{'muggfali_rate'}:</label>
                                <input className="form-control" type="number" name={'muggfali_rate'} value={formData['muggfali_rate'] || ''} onChange={(e) => setFormData({ ...formData, muggfali_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'chana_weight_p_student'} className="form-label">{'chana_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'chana_weight_p_student'} value={formData['chana_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, chana_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'chana_rate'} className="form-label">{'chana_rate'}:</label>
                                <input className="form-control" type="number" name={'chana_rate'} value={formData['chana_rate'] || ''} onChange={(e) => setFormData({ ...formData, chana_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'matar_weight_p_student'} className="form-label">{'matar_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'matar_weight_p_student'} value={formData['matar_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, matar_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'matar_rate'} className="form-label">{'matar_rate'}:</label>
                                <input className="form-control" type="number" name={'matar_rate'} value={formData['matar_rate'] || ''} onChange={(e) => setFormData({ ...formData, matar_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'suji_weight_p_student'} className="form-label">{'suji_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'suji_weight_p_student'} value={formData['suji_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, suji_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'suji_rate'} className="form-label">{'suji_rate'}:</label>
                                <input className="form-control" type="number" name={'suji_rate'} value={formData['suji_rate'] || ''} onChange={(e) => setFormData({ ...formData, suji_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'daliya_weight_p_student'} className="form-label">{'daliya_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'daliya_weight_p_student'} value={formData['daliya_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, daliya_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'daliya_rate'} className="form-label">{'daliya_rate'}:</label>
                                <input className="form-control" type="number" name={'daliya_rate'} value={formData['daliya_rate'] || ''} onChange={(e) => setFormData({ ...formData, daliya_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'dalda_weight_p_student'} className="form-label">{'dalda_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'dalda_weight_p_student'} value={formData['dalda_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, dalda_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'dalda_rate'} className="form-label">{'dalda_rate'}:</label>
                                <input className="form-control" type="number" name={'dalda_rate'} value={formData['dalda_rate'] || ''} onChange={(e) => setFormData({ ...formData, dalda_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_shakkar_weight_p_student'} className="form-label">{'nasta_shakkar_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'nasta_shakkar_weight_p_student'} value={formData['nasta_shakkar_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, nasta_shakkar_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_shakkar_rate'} className="form-label">{'nasta_shakkar_rate'}:</label>
                                <input className="form-control" type="number" name={'nasta_shakkar_rate'} value={formData['nasta_shakkar_rate'] || ''} onChange={(e) => setFormData({ ...formData, nasta_shakkar_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label htmlFor={'nasta_shakkar_suji_weight_p_student'} className="form-label">{'nasta_shakkar_suji_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'nasta_shakkar_suji_weight_p_student'} value={formData['nasta_shakkar_suji_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, nasta_shakkar_suji_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'chaypatti_weight_p_student'} className="form-label">{'chaypatti_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'chaypatti_weight_p_student'} value={formData['chaypatti_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, chaypatti_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'chaypatti_rate'} className="form-label">{'chaypatti_rate'}:</label>
                                <input className="form-control" type="number" name={'chaypatti_rate'} value={formData['chaypatti_rate'] || ''} onChange={(e) => setFormData({ ...formData, chaypatti_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_namak_weight_p_student'} className="form-label">{'nasta_namak_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'nasta_namak_weight_p_student'} value={formData['nasta_namak_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, nasta_namak_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_namak_rate'} className="form-label">{'nasta_namak_rate'}:</label>
                                <input className="form-control" type="number" name={'nasta_namak_rate'} value={formData['nasta_namak_rate'] || ''} onChange={(e) => setFormData({ ...formData, nasta_namak_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_haldi_weight_p_student'} className="form-label">{'nasta_haldi_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'nasta_haldi_weight_p_student'} value={formData['nasta_haldi_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, nasta_haldi_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_haldi_rate'} className="form-label">{'nasta_haldi_rate'}:</label>
                                <input className="form-control" type="number" name={'nasta_haldi_rate'} value={formData['nasta_haldi_rate'] || ''} onChange={(e) => setFormData({ ...formData, nasta_haldi_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_mirchi_weight_p_student'} className="form-label">{'nasta_mirchi_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'nasta_mirchi_weight_p_student'} value={formData['nasta_mirchi_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, nasta_mirchi_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_mirchi_rate'} className="form-label">{'nasta_mirchi_rate'}:</label>
                                <input className="form-control" type="number" name={'nasta_mirchi_rate'} value={formData['nasta_mirchi_rate'] || ''} onChange={(e) => setFormData({ ...formData, nasta_mirchi_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_dhaniya_weight_p_student'} className="form-label">{'nasta_dhaniya_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'nasta_dhaniya_weight_p_student'} value={formData['nasta_dhaniya_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, nasta_dhaniya_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_dhaniya_rate'} className="form-label">{'nasta_dhaniya_rate'}:</label>
                                <input className="form-control" type="number" name={'nasta_dhaniya_rate'} value={formData['nasta_dhaniya_rate'] || ''} onChange={(e) => setFormData({ ...formData, nasta_dhaniya_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_tel_weight_p_student'} className="form-label">{'nasta_tel_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'nasta_tel_weight_p_student'} value={formData['nasta_tel_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, nasta_tel_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_tel_rate'} className="form-label">{'nasta_tel_rate'}:</label>
                                <input className="form-control" type="number" name={'nasta_tel_rate'} value={formData['nasta_tel_rate'] || ''} onChange={(e) => setFormData({ ...formData, nasta_tel_rate: e.target.value })} />
                            </div>
                        </div>  
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_jeera_weight_p_student'} className="form-label">{'nasta_jeera_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'nasta_jeera_weight_p_student'} value={formData['nasta_jeera_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, nasta_jeera_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_jeera_rate'} className="form-label">{'nasta_jeera_rate'}:</label>
                                <input className="form-control" type="number" name={'nasta_jeera_rate'} value={formData['nasta_jeera_rate'] || ''} onChange={(e) => setFormData({ ...formData, nasta_jeera_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_pyaj_weight_p_student'} className="form-label">{'nasta_pyaj_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'nasta_pyaj_weight_p_student'} value={formData['nasta_pyaj_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, nasta_pyaj_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'bathing_soap_rate'} className="form-label">{'bathing_soap_rate'}:</label>
                                <input className="form-control" type="number" name={'bathing_soap_rate'} value={formData['bathing_soap_rate'] || ''} onChange={(e) => setFormData({ ...formData, bathing_soap_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'washing_soap_rate'} className="form-label">{'washing_soap_rate'}:</label>
                                <input className="form-control" type="number" name={'washing_soap_rate'} value={formData['washing_soap_rate'] || ''} onChange={(e) => setFormData({ ...formData, washing_soap_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nirma_rate'} className="form-label">{'nirma_rate'}:</label>
                                <input className="form-control" type="number" name={'nirma_rate'} value={formData['nirma_rate'] || ''} onChange={(e) => setFormData({ ...formData, nirma_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'colgate_rate'} className="form-label">{'colgate_rate'}:</label>
                                <input className="form-control" type="number" name={'colgate_rate'} value={formData['colgate_rate'] || ''} onChange={(e) => setFormData({ ...formData, colgate_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'amla_tel_rate'} className="form-label">{'amla_tel_rate'}:</label>
                                <input className="form-control" type="number" name={'amla_tel_rate'} value={formData['amla_tel_rate'] || ''} onChange={(e) => setFormData({ ...formData, amla_tel_rate: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'lakdi_weight_p_student'} className="form-label">{'lakdi_weight_p_student'}:</label>
                                <input className="form-control" type="number" name={'lakdi_weight_p_student'} value={formData['lakdi_weight_p_student'] || ''} onChange={(e) => setFormData({ ...formData, lakdi_weight_p_student: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'lakdi_rate'} className="form-label">{'lakdi_rate'}:</label>
                                <input className="form-control" type="number" name={'lakdi_rate'} value={formData['lakdi_rate'] || ''} onChange={(e) => setFormData({ ...formData, lakdi_rate: e.target.value })} />
                            </div>
                        </div>
                        
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_lakdi_weight'} className="form-label">{'nasta_lakdi_weight'}:</label>
                                <input className="form-control" type="number" name={'nasta_lakdi_weight'} value={formData['nasta_lakdi_weight'] || ''} onChange={(e) => setFormData({ ...formData, nasta_lakdi_weight: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor={'nasta_lakdi_rate'} className="form-label">{'nasta_lakdi_rate'}:</label>
                                <input className="form-control" type="number" name={'nasta_lakdi_rate'} value={formData['nasta_lakdi_rate'] || ''} onChange={(e) => setFormData({ ...formData, nasta_lakdi_rate: e.target.value })} />
                            </div>
                        </div>
                        
                        

                    </div>
                    <input type='hidden' value={localStorage.getItem('Ashram_id')} name="Ashram_id"></input>
                    <Button className='mt-3' variant="contained" type="submit">Submit</Button>
                </form>
            </div>
        </div>
        </div>
        </div>
        </div>
    );
}

export default Update_add_kiranaItem;
